<template>
    <div v-if="popup && show" class="popup-layer" :style="popupStyle">
        <div class="popup-layer__inner">
            <a class="popup-layer__body" :href="popup.url" target="_blank">
                <div class="popup-layer-content" v-html="popup.content.replace(/\n/g, '<br>')"></div>
            </a>
            <div class="popup-layer__foot">
                <v-row align="center" justify="space-between" class="row--x-small">
                    <v-col cols="auto">
                        <v-checkbox v-model="doNotOpenToday" label="오늘하루 열지않음" :value="true" hide-details></v-checkbox>
                    </v-col>
                    <v-col cols="auto" class="line-height-0">
                        <v-btn @click="close" small text class="px-4 py-0 min-w-auto h-auto font-weight-regular grey--text text--darken-1">닫기</v-btn>
                    </v-col>
                </v-row>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        popup: { type: Object, default: null }
    },
    data() {
        return {
            doNotOpenToday: false,
            show: false,
        };
    },
	mounted() {
		this.show = !this.$cookies.get(this.popup?._id);
	},
	methods : {
        close(){
            if(this.doNotOpenToday){
                this.$cookies.set(this.popup._id, "hidden", "1d");
            }
            this.show = false;
        }
	},
    computed: {
        popupStyle(){
            return {
                left: this.popup.left + 'px',
                top: this.popup.top + 'px',
                width: this.popup.width + 'px',
                height: this.popup.height + 'px'
            }
        }
    }
}
</script>

<style scoped>
</style>
